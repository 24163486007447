// import 'bootstrap';

import Main from './Main';
import Carousel from './components/Carousel';
import CookieBanner from './components/CookieBanner';
import Header from './components/Header';
import Search from './components/Search';
import SignUpForm from './components/SignUpForm';

/** Styles **/
import './styles/app.scss';

console.info('Sofia Health');

let modules = [
	Carousel,
	CookieBanner,
	Header,
	Search,
	SignUpForm,
];

let core = new Main(modules);
core.init();
